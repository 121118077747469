<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title>Edit Vendor </v-card-title>
              </v-card>

               <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    
                  
                    <v-col cols="12" sm="3" md="2">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Name of the Company"
                        
                         
                        outlined
                        dense
                        v-model="customer.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        ref="contactPerson"
                        color="black"
                        label="Contact Person"
                        outlined
                         
                        dense
                        v-model="customer.contactPerson"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email ID"
                       
                        outlined
                         
                        dense
                        v-model="customer.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                         
                        ref="phone"
                        color="black"
                        label="Phone Number"
                        outlined
                        :rules="phoneRules"
                        dense
                        v-model="customer.phone"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                         
                        ref="fax"
                        color="black"
                        label="Fax"
                        outlined
                        dense
                        v-model="customer.fax"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Sales Rep."
                        outlined
                        dense
                         disabled
                        v-model="agentCode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

          <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row> -->

                  

                  <!-- <v-row class="ml-1 mt-4" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row> -->

                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="reference"
                        color="black"
                        label="Reference"
                        outlined
                        dense
                        v-model="customer.reference"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                     <v-select
                        :items="qtTypes"
                        ref="type"
                        color="black"
                        label="Type"
                        outlined
                        item-text="type"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="qtStock"
                        ref="type"
                        color="black"
                        label="Stock"
                        outlined
                        item-text="stock"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="Shape"
                        color="black"
                        label="Shape"
                        outlined
                        dense
                        v-model="customer.shape"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="width"
                        color="black"
                        label="Width"
                        outlined
                        dense
                        v-model="customer.width"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="length"
                        color="black"
                        label="Length"
                        outlined
                        dense
                        v-model="customer.lenght"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                       <v-text-field
                        ref="colors"
                        color="black"
                        label="Colors"
                        outlined
                        dense
                        v-model="customer.colors"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="copy"
                        color="black"
                        label="Copies"
                        outlined
                        dense
                        v-model="customer.copy"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                    <v-select
                        :items="qtOptions"
                        ref="type"
                        color="black"
                        label="Options"
                        multiple
                        outlined
                        item-text="options"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                      </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                  
                    <v-col md="4">
                      <v-text-field
                        ref="platecharge"
                        color="black"
                        label="Plate Charge"
                        outlined
                        dense
                        v-model="customer.plateCharge"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="atWork"
                        color="black"
                        label="Art Work"
                        outlined
                        dense
                        v-model="customer.atWork"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        ref="copyChange"
                        color="black"
                        label="Copy Change"
                        outlined
                        dense
                        v-model="customer.copyChange"
                      ></v-text-field>
                      </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="colorChange"
                        color="black"
                        label="Color Change"
                        outlined
                        dense
                         
                        v-model="customer.colorChange"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="colorMatch"
                        color="black"
                        label="Color Match"
                        outlined
                        dense
                        v-model="customer.colorMatch"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="die"
                        color="black"
                        label="Die Charge"
                        outlined
                        dense
                        v-model="customer.die"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="qtQuantity"
                        ref="terms"
                        color="black"
                        label="Select Qty(k=1000)"
                        outlined
                         
                        item-text="name"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.quantity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity1"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.quantity1"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="quantity2"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.quantity2"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                         
                        color="black"
                        label="Price"
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    
                     <v-col md="3">
                      <v-text-field
                        ref="price"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.price"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="price1"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.price1"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="price2"
                        color="black"
                         
                        outlined
                        dense
                        v-model="customer.price2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="10">
                          <v-text-field
                            
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i > 0"
                            class="mx-2"
                            depressed
                            fab
                            dark
                            small
                            color="error"
                            @click="removee(i)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i == notes.length - 1"
                            fab
                            depressed
                            small
                            color="primary"
                            @click="update"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-4" outlined>
                <v-card-title>
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 text-center>
                      <v-btn
                        dark
                        color="primary"
                        depressed
                        class="text-capitalize"
                        @click="validate()"
                        :ripple="false"
                      >
                        Submit
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer absolute permanent right width="55">
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon>mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/addCustomer" title="Add">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
//import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      companyRules: [
        (v) => !!v || "Company Name is required",
        (v) =>
          (v && v.length <= 50) ||
          "Company Name must be less than 50 characters",
      ],
      numberrules: [
        // (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      customer: {
        id: null,
        companyName: null,
        contactPerson: null,
        email: null,
        nature: null,
        address: null,
        city: null,
        stateId: null,
        countryId: null,
        zip: null,
        phone: null,
        fax: null,
        shipAddress: null,
        shipCity: null,
        shipStateId: null,
        shipCountryId: null,
        shipZip: null,
        birthDate: null,
        initialDate: new Date().toISOString().substr(0, 10),
        lastDate: null,
        termId: null,
        age: null,
        agentCode: null,
        ytd: null,
        means: null,
        rating: null,
        accountNo: null,
        status: "Active",
        description: null,
        shape:null,
        reference:null,
        width:null,
        length:null,
        colors:null,
        plateCharge:null,
        atWork:null,
        copyChange:null,
        colorChange:null,
        colorMatch:null,
        die:null,
        quantity:null,
        quantity1:null,
        quantity2:null,
        price:null,
        price1:null,
        price2:null,
      },
      notes: [
        {
          note: "",
        },
      ],
      phoneno:null,
      showsnackbar: false,
      msg: null,
      ServerError: false,
      validateInput: null,
      timeout: 5000,
      appLoading: false,
      msg1: null,
      userData: [],
      allStatus: [],
      terms: [],
      states: [],
      qtTypes:[],
      qtStock:[],
      qtOptions:[],
      qtQuantity:[],
      menu1: false,
      menu3: false,
      menu2: false,
     agentCode:localStorage.getItem("agentCode"),
    };
  },

  beforeMount() {
    this.getCustomer();
    // this.stateList();
   this.quoteType();
    this.quoteStock();
    this.quoteOptions();
    this.quoteQunatity();
  },
  watch:{
    phoneno() {
      //  console.log("here")
      var str = this.phoneno;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phoneno = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phoneno = ind + "-";
      }
    },
  },
  methods: {
    update() {
      this.notes.push({
        note: "",
      });
    },
    removee(index) {
      this.notes.splice(index, 1);
    },
    validate() {
      if (this.$refs.form.validate()) this.editCustomer();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quote/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.customer = response.data.data;
            this.phoneno = response.data.data.phone;
            if (response.data.data.initialDate)
              this.customer.initialDate = this.ChangeFormateDate(
                response.data.data.initialDate
              );
            if (response.data.data.lastDate)
              this.customer.lastDate = this.ChangeFormateDate(
                response.data.data.lastDate
              );
            if (response.data.data.birthDate)
              this.customer.birthDate = this.ChangeFormateDate(
                response.data.data.birthDate
              );
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  quoteType() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/type/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtTypes = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteStock() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/stock/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtStock = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteOptions() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/options/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtOptions = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteQunatity() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quote/quantity",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtQuantity = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    editCustomer() {
      this.appLoading = true;
      this.customer.phone = this.phoneno;
      axios({
        method: "POST",
        url: "/customer/edit/" + this.$route.params.id,
        data: this.customer,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
             if(this.notes)
            this.editNotes();
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.getCustomer();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
     editNotes(){
       axios({
        method: "POST",
        url: "/note/edit",
        data: {
         customerId: this.$route.params.id,
         notes:this.notes
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
           console.log(response.data.msg) 
           
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    ChangeFormateDate(oldDate) {
      var newdateFormat = oldDate.toString().split("T");
      return newdateFormat[0];
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black;
}
.v-text-field input {
  color: black !important;
}
.v-text-field__slot textarea {
  color: black !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
</style>